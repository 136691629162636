@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@tailwind base;

@tailwind components;

@tailwind utilities;

body{
    @apply font-Barlow text-[15px] font-normal text-[#999]
}
.Header__Links{
    @apply text-white text-[15px] tracking-[2px] uppercase font-Oswald transition-all duration-500 cursor-pointer hover:text-mainText
}
.Active{
    @apply text-mainText
}
.Text-Strock{
    -webkit-text-stroke: 1px #fff;
}
.Text-Strock-Title{
    -webkit-text-stroke: 1px #904d30;
    color: transparent;
}


/* Accardion */

.card {
   overflow: hidden;
}
.rc-accordion-toggle {
   display: flex;
   align-items: flex-start;
   cursor: pointer;
   justify-content: space-between;
   transition: 0.3s;
}
.rc-accordion-toggle.active{
   /* background-color: #5a8dee; */
}

.rc-accordion-card{
   overflow: hidden;
}
.rc-accordion-card:last-child{
   margin-bottom: 0;
}
.rc-accordion-title{
   position: relative;
   transition: 0.3s;
}
.rc-accordion-toggle.active .rc-accordion-title{
   /* color: #fff; */
}
.rc-accordion-icon{
   position: relative;
   top: 2px;
   transition: 0.35s;
   font-size: 12px;
}
.rc-accordion-body{
   flex: 1 1 auto;
   min-height: 1px;
   padding: 15px;
}

.rc-collapse{
   position: relative;
   height: 0;
   overflow: hidden;
   transition: height 0.35s ease;
}
.rc-collapse.show{
   height: auto;
}

/* Accardion  End*/


.For_Small .TextTitle{
   @apply text-[27px]
}

.ddl-select {
   visibility:hidden;
 }
 .ddl {
   position:relative;
   height:54.5px;
   width:100%;
   text-align:initial;
   color:#999;
   padding:0 !important;
 }
 .ddl::after {
   content:'';
   position:absolute;
   top:25px;
   right:20px;
   width:12px;
   height:2px;
   background:#555;
   z-index:99;
   transform:rotate(-40deg);
   transition:0.5s;
 }
 .ddl::before {
   content:'';
   position:absolute;
   top:25px;
   right:28px;
   width:12px;
   height:2px;
   background:#555;
   z-index:99;
   transform:rotate(40deg);
   transition:0.5s;
 }
 /* .ddl.active::after{
   right:28px;
 }
 .ddl.active::before{
   right:20px;
 } */
 .ddl-input {
   position:absolute;
   top:0;
   left:0;
   width:100%;
   height:100%;
   cursor:pointer;
   /* border-radius:10px; */
   padding:10px 14px;
   outline:none;
   font-size:15px;
   background:transparent;
   border:1px solid rgba(255,255,255,0.1);
 }
 .ddl.active .ddl-options {
   visibility:visible;
   opacity:1;
 }
 .ddl .ddl-options {
   position:absolute;
   width:100%;
   top:55px;
   background:#222222;
   overflow:hidden;
   visibility:hidden;
   opacity:0;
   transition:0.25s;
   z-index: 999;
   /* box-shadow: 0 0 5px rgba(0,0,0,0.10); */
 }
 .ddl .ddl-options > div {
   padding: 10px 20px;
   cursor:pointer;
   border-bottom:1px solid rgba(255,255,255, 0.1);
   transition:all 0.25s linear;
 }
 .ddl .ddl-options > div:hover {
   background: #904d30;
   color: #fff;
 }

/* 
 input[type="date"]::-webkit-inner-spin-button,
 input[type="date"]::-webkit-calendar-picker-indicator::after {
     color:#fff !important ;
     stroke:#999 !important ;
     background:#999 !important ;
 } */

 input{
   color-scheme:dark;
 }

.ddl-select1 {
   visibility:hidden;
 }
 .ddl1 {
   position:relative;
   height:54.5px;
   width:100%;
   text-align:initial;
   color:#999;
   padding:0 !important;
 }
 .ddl1::after {
   content:'';
   position:absolute;
   top:25px;
   right:20px;
   width:12px;
   height:2px;
   background:#555;
   z-index:99;
   transform:rotate(-40deg);
   transition:0.5s;
 }
 .ddl1::before {
   content:'';
   position:absolute;
   top:25px;
   right:28px;
   width:12px;
   height:2px;
   background:#555;
   z-index:99;
   transform:rotate(40deg);
   transition:0.5s;
 }
 /* .ddl.active::after{
   right:28px;
 }
 .ddl.active::before{
   right:20px;
 } */
 .ddl-input1 {
   position:absolute;
   top:0;
   left:0;
   width:100%;
   height:100%;
   cursor:pointer;
   /* border-radius:10px; */
   padding:10px 14px;
   outline:none;
   font-size:15px;
   background:transparent;
   border:1px solid rgba(255,255,255,0.1);
 }
 .ddl1.active .ddl1-options {
   visibility:visible;
   opacity:1;
 }
 .ddl1 .ddl1-options {
   position:absolute;
   width:100%;
   top:55px;
   background:#222222;
   overflow:hidden;
   visibility:hidden;
   opacity:0;
   transition:0.25s;
   z-index: 999;
   /* box-shadow: 0 0 5px rgba(0,0,0,0.10); */
 }
 .ddl1 .ddl1-options > div {
   padding: 10px 20px;
   cursor:pointer;
   border-bottom:1px solid rgba(255,255,255, 0.1);
   transition:all 0.25s linear;
 }
 .ddl1 .ddl1-options > div:hover {
   background: #904d30;
   color: #fff;
 }
 .TeamSlider .slick-dots{
  @apply lg:-bottom-[80px] !important;
 }
 .TeamSlider .slick-dots li button::before{
    border:1px solid rgba(255,255,255,.5);
    border-radius:50%;
 }
 .TeamSlider .slick-active button::before{
  background:white;
  color:white !important;
 }

 .lg-react-element{
  @apply  w-full flex  justify-center space-x-2 sm:space-x-12
 }
 .aaa{
  /*border:1px solid red !important;
  width:fit-content !important;*/
  @apply w-1/3
 }
 .lg-react-element  img{
  @apply w-full
 }